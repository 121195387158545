import React from 'react';
import ReactDOM from 'react-dom';
// import { HashRouter } from 'react-router-dom';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "@awsui/global-styles/index.css";
import {App, AppLayoutUI} from './pages/App';
import {Login, ForgotPassword, OTPPAGE,ResetPassword} from "./pages/Login"
// import  {ForgotPassword} from "./pages/ForgotPassword"
// import { OTPPAGE} from "./pages/Otppage"
// import { ResetPassword} from "./pages/ResetPassword"
// import Content from "./Content";
// import Login from "./Login";

// ReactDOM.render(
//     <HashRouter>
//         <App/>
//     </HashRouter>,
//     document.getElementById('root')
// );
// const root = ReactDOM.createRoot(document.getElementById("root"));
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route index element={<App />} />
        <Route path="login" element={<Login />} />
        <Route path="otppage" element={<OTPPAGE />}/>
        <Route path="resetpassword" element={<ResetPassword />}/>
        <Route path="forgotpassword" element={<ForgotPassword />}/>
        <Route path="content" element={<AppLayoutUI />}/>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);